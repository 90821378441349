<!-- 免去登录，直接授权 -->
<template>
    <div></div>
</template>
  
<script>
let Base64 = require('js-base64').Base64;
//   import CommonApi from '@/api/CommonApi'
export default {
    data() {
        return {
            info: {},
            loginResponse: {}
        }
    },
    async created() {

        // eyJhY2Nlc3NfdG9rZW4iOiIwNWNlZjE5MS1iNTZmLTRjNGEtYmQxNC05MzFkZWQ5MmUyYzEiLCJ0b2tlbl90eXBlIjoiYmVhcmVyIiwicmVmcmVzaF90b2tlbiI6IjM1NWMwNmJjLTMzNTgtNDNjNC04MDc1LTM0YTIwOGY5NmJjOSIsImV4cGlyZXNfaW4iOjk5OTk5LCJ0ZW5hbnRPcmdJZCI6ImFkYTZjZTUzNWI5YmVhODdhMGVmN2ZhOTZiYmM1ZTI3IiwidGVuYW50SWQiOiIwMjdkMzcwMzBmMDc4ZGQyMGRjZWQxYTQ1YWJjMDQwZiIsInVzZXJJZCI6ImE0ZWE2NDk4MjdmMzBhOGFiMDdjMGI1NzRhNmI0OTE0Iiwic2NvcGUiOiJhcHAiLCJ0eXBlIjowfQ%3D%3D
        this.info = JSON.parse(Base64.decode(this.$route.query.data));
        console.log('info', this.info);

        this.$store.commit(
            'SET_AUTHORIZATION',
            this.info.data.token_type + ' ' + this.info.data.access_token
        )


        this.loginResponse = await this.$api
            .queryUserDetails(
                {
                    id: this.info.data.userId
                }
            )
        console.log('this.loginResponse', this.loginResponse);
        if (this.loginResponse.code == 200) {
            // 基本信息
            this.$store.commit('SET_USERINFO', this.loginResponse.data)
            console.log(this.loginResponse.data.name)
            this.$store.commit('SET_USERNICKNAME', this.loginResponse.data.name)
            this.$store.commit(
              'SET_PHONE',
              this.loginResponse.data.phone
            )

            this.$router.push({ name: 'home' })
        }
        // sessionStorage.setItem('isBack', true)
        // console.log(document.referrer)
        // console.log(">>>>>>>>>>>>>>>>>>>")
        // if (document.referrer.includes("https://paas.tsingning.com")) {
        //     sessionStorage.setItem('isBack', true)
        // } else {
        //     sessionStorage.setItem('isBack', '')
        // }

    },
}
</script>
<style scoped>

</style>